import { createWebHashHistory, createRouter } from "vue-router";
const roleId = parseInt(localStorage.getItem("roleId"));
const routes = roleId !== 3?[
  {path: "/",
    // component: () => import("@/components/Layouts/LayoutUserManagment.vue"),
    children: [
      {
        path: "/ownerbuilding-list",
        name: "ownerbuilding-list",
        component: () =>
          import("@/views/Buildings/OwnerBuilding/ListOwnerBuilding.vue"),
      },
      {
        path: "/view-ownerbuilding/:id",
        name: "show-ownerbuilding",
        component: () =>
          import("@/views/Buildings/OwnerBuilding/ShowOwnerBuilding.vue"),
      },
      {
        path: "/create-ownerbuilding",
        name: "create-ownerbuilding",
        component: () =>
          import("@/views/Buildings/OwnerBuilding/CreateOwnerBuilding.vue"),
      },
      {
        path: "/update-ownerbuilding/:id",
        name: "update-ownerbuilding",
        component: () =>
          import("@/views/Buildings/OwnerBuilding/UpdateOwnerBuilding.vue"),
      },

      {
        path: "/broker-list",
        name: "broker-list",
        component: () => import("@/views/BrokerManagement/FormListBroker.vue"),
      },
      {
        path: "/create-broker",
        name: "create-broker",
        component: () => import("@/views/BrokerManagement/CreateBroker.vue"),
      },
      
      {
        path: "/view-broker/:id",
        name: "show-broker",
        component: () => import("@/views/BrokerManagement/ShowBroker.vue"),
      },

      {
        path: "/update-broker/:id",
        name: "update-broker",
        component: () => import("@/views/BrokerManagement/UpdateBroker.vue"),
      },

      {
        path: "/change-password-broker/:id",
        name: "change-password-broker",
        component: () => import("@/views/BrokerManagement/ChangePasswordBroker.vue"),
      },

      

      {
        path: "/assettype-list",
        name: "assettype-list",
        component: () =>
          import("@/views/UserManagement/AssetType/AssettypeList.vue"),
      },
      {
        path: "/create-assettype",
        name: "create-assettype",
        component: () =>
          import("@/views/UserManagement/AssetType/CreateAssetype.vue"),
      },
      {
        path: "/view-assettype/:id",
        name: "show-assettype",
        component: () =>
          import("@/views/UserManagement/AssetType/ShowAssettype.vue"),
      },
      {
        path: "/update-assettype/:id",
        name: "update-assettype",
        component: () =>
          import("@/views/UserManagement/AssetType/UpdateAssettype.vue"),
      },

      {
        path: "/setup-permission",
        name: "setup-permission",
        component: () =>
          import("@/views/UserManagement/Permission/SetupPermission.vue"),
      },
      // {
      //   path: "/sidebar",
      //   name: "sidebar",
      //   component: () => import("@/components/Layouts/SideBar.vue"),
      // },

      {
        path: "/building-list",
        name: "building-list",
        component: () =>
          import("@/views/Buildings/Building/FormListBuilding.vue"),
      },
      {
        path: "/view-building/:id",
        name: "view-building",
        component: () => import("@/views/Buildings/Building/ShowBuilding.vue"),
      },
      {
        path: "/update-building/:id",
        name: "update-building",
        component: () =>
          import("@/views/Buildings/Building/UpdateBuilding.vue"),
      },
      {
        path: "/create-building",
        name: "create-building",
        component: () =>
          import("@/views/Buildings/Building/CreateBuilding.vue"),
      },

      {
        path: "/create-menu",
        name: "create-menu",
        component: () => import("@/views/UserManagement/menu/CreateMenu.vue"),
      },
      {
        path: "/menu-list",
        name: "menu-list",
        component: () => import("@/views/UserManagement/menu/MenuList.vue"),
      },
      {
        path: "/view-menu/:id",
        name: "show-menu",
        component: () => import("@/views/UserManagement/menu/ShowMenu.vue"),
      },
      {
        path: "/update-menu/:id",
        name: "update-menu",
        component: () => import("@/views/UserManagement/menu/UpdateMenu.vue"),
      },

      {
        path: "/create-submenu",
        name: "create-submenu",
        component: () =>
          import("@/views/UserManagement/Submenu/CreateSubmenu.vue"),
      },
      {
        path: "/upate-submenu/:id",
        name: "update-submenu",
        component: () =>
          import("@/views/UserManagement/Submenu/UpdateSubmenu.vue"),
      },
      {
        path: "/submenu-list",
        name: "submenu-list",
        component: () =>
          import("@/views/UserManagement/Submenu/SubmenuList.vue"),
      },
      {
        path: "/view-submenu/:id",
        name: "view-submenu",
        component: () =>
          import("@/views/UserManagement/Submenu/ShowSubmenu.vue"),
      },

      {
        path: "/create-vault",
        name: "create-vault",
        component: () => import("@/views/CashManagement/Vault/CreateVault.vue"),
      },
      {
        path: "/vault-list",
        name: "list-vault",
        component: () => import("@/views/CashManagement/Vault/VaultList.vue"),
      },
      {
        path: "/view-vault/:id",
        name: "show-vault",
        component: () => import("@/views/CashManagement/Vault/ShowVault.vue"),
      },

      {
        path: "/detail-vault/:id",
        name: "detail-vault",
        component: () => import("@/views/CashManagement/Vault/VaultDetail.vue"),
      },

      {
        path: "/till-list",
        name: "till-list",
        component: () => import("@/views/CashManagement/Till/FormListTill.vue"),
      },
      {
        path: "/create-till",
        name: "create-till",
        component: () => import("@/views/CashManagement/Till/CreateTill.vue"),
      },
      {
        path: "/view-till/:id",
        name: "show-till",
        component: () => import("@/views/CashManagement/Till/ShowTill.vue"),
      },

      {
        path: "/detail-till/:id",
        name: "detail-till",
        component: () => import("@/views/CashManagement/Till/TillDetail.vue"),
      },


      {
        path: "/account-list",
        name: "account-list",
        component: () =>
          import("@/views/CashManagement/OpenAccount/AccountList.vue"),
      },
      {
        path: "/open_account",
        name: "open_account",
        component: () =>
          import("@/views/CashManagement/OpenAccount/OpentAccount.vue"),
      },

      {
        path: "/deposit-list",
        name: "deposit-list",
        component: () =>
          import("@/views/CashManagement/Deposit/DepositList.vue"),
      },
      {
        path: "/deposit",
        name: "deposit",
        component: () =>
          import("@/views/CashManagement/Deposit/DepositForm.vue"),
      },

      {
        path: "/withdrawal-list",
        name: "withdrawal-list",
        component: () =>
          import("@/views/CashManagement/Withdrawal/WithdrawalList.vue"),
      },
      {
        path: "/withdrawal",
        name: "withdrawal",
        component: () =>
          import("@/views/CashManagement/Withdrawal/WithdrawalForm.vue"),
      },
      {
        path: "/cash-transactions",
        name: "cash-transactions",
        component: () =>
          import("@/views/CashManagement/CashTransaction/CashTransactions.vue"),
      },

      {
        path: "/transfer/list",
        name: "transfer/list",
        component: () =>
          import("@/views/CashManagement/Transfers/ListTransfer.vue"),
      },
      {
        path: "/transfer/create",
        name: "transfer/create",
        component: () =>
          import("@/views/CashManagement/Transfers/CreateTransfer.vue"),
      },
      {
        path: "/transfer/view",
        name: "transfer/view",
        component: () =>
          import("@/views/CashManagement/Transfers/ShowTransfer.vue"),
      },

      {
        path: "/create-register",
        name: "create-register",
        component: () => import("@/views/UserManagement/UserRegister/Register.vue"),
      },
      
      {
        path: "/register-list",
        name: "register-list",
        component: () => import("@/views/UserManagement/UserRegister/registerList.vue"),
      },
      {
        path: "/update-register/:id",
        name: "update-register",
        component: () => import("@/views/UserManagement/UserRegister/UpdateUserregister.vue"),
      },
      {
        path: "",
        name: "homepage-user",
        component: () => import("@/views/UserManagement/UserHomePage.vue"),
      },

      {
        path: "/user-list",
        name: "user-list",
        component: () =>
          import("@/views/UserManagement/User/FormListUsers.vue"),
      },
      {
        path: "/create-user",
        name: "create-user",
        component: () =>
          import("@/views/UserManagement/User/CreateFormUser.vue"),
      },
      {
        path: "/update-user/:id",
        name: "update-user",
        component: () =>
          import("@/views/UserManagement/User/UpdateFormUser.vue"),
      },
      {
        path: "/view-user/:id",
        name: "view-user",
        component: () => import("@/views/UserManagement/User/ShowUser.vue"),
      },

      {
        path: "/role-list",
        name: "role-list",
        component: () => import("@/views/UserManagement/Role/FormListRole.vue"),
      },
      {
        path: "/create-role",
        name: "create-role",
        component: () => import("@/views/UserManagement/Role/CreateRole.vue"),
      },
      {
        path: "/update-role/:id",
        name: "update-role",
        component: () => import("@/views/UserManagement/Role/UpateRole.vue"),
      },
      {
        path: "/view-role/:id",
        name: "view-role",
        component: () => import("@/views/UserManagement/Role/ShowRole.vue"),
      },

      {
        path: "/branch-list",
        name: "branch-list",
        component: () =>
          import("@/views/UserManagement/Branch/FormListBranch.vue"),
      },
      {
        path: "/create-branch",
        name: "create-branch",
        component: () =>
          import("@/views/UserManagement/Branch/CreateBranch.vue"),
      },
      {
        path: "/update-branch/:id",
        name: "update-branch",
        component: () =>
          import("@/views/UserManagement/Branch/UpdateBranch.vue"),
      },
      {
        path: "/view-branch/:id",
        name: "view-branch",
        component: () => import("@/views/UserManagement/Branch/ShowBranch.vue"),
      },

      {
        path: "/department-list",
        name: "department-list",
        component: () =>
          import("@/views/UserManagement/Department/FormListDepartment.vue"),
      },
      {
        path: "/create-department",
        name: "create-department",
        component: () =>
          import("@/views/UserManagement/Department/CreateDepartment.vue"),
      },
      {
        path: "/update-department/:id",
        name: "update-department",
        component: () =>
          import("@/views/UserManagement/Department/UpdateDepartment.vue"),
      },
      {
        path: "/view-department/:id",
        name: "view-department",
        component: () =>
          import("@/views/UserManagement/Department/ShowDepartment.vue"),
      },

      {
        path: "/position-list",
        name: "position-list",
        component: () =>
          import("@/views/UserManagement/Position/FormListPosition.vue"),
      },
      {
        path: "/create-position",
        name: "create-position",
        component: () =>
          import("@/views/UserManagement/Position/CreatePosition.vue"),
      },
      {
        path: "/update-position/:id",
        name: "update-position",
        component: () =>
          import("@/views/UserManagement/Position/UpdatePosition.vue"),
      },
      {
        path: "/view-position/:id",
        name: "view-position",
        component: () =>
          import("@/views/UserManagement/Position/ShowPosition.vue"),
      },

      {
        path: "/buildingtype-list",
        name: "buildingtype-list",
        component: () =>
          import("@/views/Buildings/BuildingType/FormListBuildingType.vue"),
      },
      {
        path: "/create-buildingtype",
        name: "create-buildingtype",
        component: () =>
          import("@/views/Buildings/BuildingType/CreateBuildingType.vue"),
      },
      {
        path: "/update-buildingtype/:id",
        name: "update-buildingtype",
        component: () =>
          import("@/views/Buildings/BuildingType/UpdateBuildingType.vue"),
      },
      {
        path: "/view-buildingtype/:id",
        name: "view-buildingtype",
        component: () =>
          import("@/views/Buildings/BuildingType/ShowBuildingType.vue"),
      },

      {
        path: "/roomtype-list",
        name: "roomtype-list",
        component: () => import("@/views/Rooms/RoomType/FormListRoomType.vue"),
      },
      {
        path: "/create-roomtype",
        name: "create-roomtype",
        component: () => import("@/views/Rooms/RoomType/CreateRoomType.vue"),
      },
      {
        path: "/update-roomtype/:id",
        name: "update-roomtype",
        component: () => import("@/views/Rooms/RoomType/UpdateRoomType.vue"),
      },
      {
        path: "/view-roomtype/:id",
        name: "view-roomtype",
        component: () => import("@/views/Rooms/RoomType/ShowRoomType.vue"),
      },

      {
        path: "/room-list",
        name: "room-list",
        component: () => import("@/views/Rooms/Room/ListRoom.vue"),
      },
      {
        path: "/create-room",
        name: "create-room",
        component: () => import("@/views/Rooms/Room/CreateRoom.vue"),
      },
      {
        path: "/update-room/:id",
        name: "update-room",
        component: () => import("@/views/Rooms/Room/UpdateRoom.vue"),
      },
      {
        path: "/view-room/:id",
        name: "show-room",
        component: () => import("@/views/Rooms/Room/ShowRoom.vue"),
      },

      {
        path: "/accounttype-list",
        name: "accounttype-list",
        component: () =>
          import("@/views/Settings/AccountType/AccountTypeList.vue"),
      },
      {
        path: "/create-accounttype",
        name: "create-accounttype",
        component: () =>
          import("@/views/Settings/AccountType/CreateAccountType.vue"),
      },
      {
        path: "/update-accounttype/:id",
        name: "update-accounttype",
        component: () =>
          import("@/views/Settings/AccountType/UpdateAccountType.vue"),
      },

      {
        path: "/fees-list",
        name: "fees-list",
        component: () => import("@/views/Administrator/Fees/FeesList"),
      },
      {
        path: "/fees-add",
        name: "fees-add",
        component: () => import("@/views/Administrator/Fees/CreateFees"),
      },
      {
        path: "/fees-update/:id",
        name: "feeds-update",
        component: () => import("@/views/Administrator/Fees/UpdateFees"),
      },

      {
        path: "/tenant-list",
        name: "tenant-list",
        component: () => import("@/views/Tenants/TenantLlist.vue"),
      },
      {
        path: "/create-tenant",
        name: "create-tenant",
        component: () => import("@/views/Tenants/AddTenant.vue"),
      },

      {
        path: "/update-tenant/:id",
        name: "update-tenant",
        component: () => import("@/views/Tenants/UpdateTenant.vue"),
      },

      {
        path: "/detail-tenant/:id",
        name: "detail-tenant/",
        component: () => import("@/views/Tenants/TetantDetails.vue"),
      },

      {
        path: "/booking-list",
        name: "booking-list",
        component: () => import("@/views/Booking/ListBooking.vue"),
      },
      {
        path: "/create-booking",
        name: "create-booking",
        component: () => import("@/views/Booking/CreateBooking.vue"),
      },
      {
        path: "/update-booking/:id",
        name: "update-booking",
        component: () => import("@/views/Booking/UpdateBooking.vue"),
      },
      // {
      //   path: "/view-booking/:id",
      //   name: "view-booking",
      //   component: () => import("@/views/Booking/ShowBooking.vue"),
      // },

      {
        path: "/create-contract",
        name: "contract",
        component: () => import("@/views/Contracts/FormContract.vue"),
      },
      {
        path: "/contract-list",
        name: "contract-list",
        component: () => import("@/views/Contracts/ListContract.vue"),
      },
      {
        path: "/update-contract/:id",
        name: "update-contract",
        component: () => import("@/views/Contracts/UpdateContract.vue"),
      },
      // {
      //   path: "/print-contract/:id",
      //   name: "print-contract",
      //   component: () => import("@/views/Contracts/PrintContract.vue"),
      // },

      {
        path: "/invoice_setting",
        name: "invoice-list",
        component: () => import("@/views/Invoices/ListInvoice.vue"),
      },
      {
        path: "/invoice_setting/create",
        name: "create-invoice",
        component: () => import("@/views/Invoices/CreateInvoice.vue"),
      },
      {
        path: "/invoice_setting/update/:id",
        name: "update-invoice",
        component: () => import("@/views/Invoices/UpdateInvoince.vue"),
      },
      // {
      //   path: "/print_invoice/:id",
      //   name: "print_invoice",
      //   component: () => import("@/views/Invoices/PrintInvioce.vue"),
      // },

      {
        path: "/receipt_setting/create",
        name: "create-receipt",
        component: () => import("@/views/Receipts/FormReceipt.vue"),
      },
      {
        path: "/receipt_setting/update/:id",
        name: "update-receipt",
        component: () => import("@/views/Receipts/UpdateReceipt.vue"),
      },
      {
        path: "/receipt_setting",
        name: "list-receipt",
        component: () => import("@/views/Receipts/ListReceipt.vue"),
      },
      // {
      //   path: "/receipt-print/:id",
      //   name: "receipt-print",
      //   component: () => import("@/views/Receipts/PrintReceipt"),
      // },

      {
        path: "/asset-category-list",
        name: "asset-category-list",
        component: () => import("@/views/Administrator/AssetCategory/List"),
      },
      {
        path: "/asset-category-add",
        name: "asset-category-add",
        component: () => import("@/views/Administrator/AssetCategory/Add"),
      },
      {
        path: "/asset-category-edit/:id",
        name: "asset-category-edit",
        component: () =>
          import("@/views/Administrator/AssetCategory/AssetCategoryEdit"),
      },

      {
        path: "/asset-subcategory-list",
        name: "asset-subcategory-list",
        component: () => import("@/views/Administrator/AssetSubCategory/List"),
      },
      {
        path: "/asset-subcategory-add",
        name: "asset-subcategory-add",
        component: () => import("@/views/Administrator/AssetSubCategory/Add"),
      },
      {
        path: "/asset-subcategory-edit/:id",
        name: "asset-subcategory-edit",
        component: () =>
          import("@/views/Administrator/AssetSubCategory/EditSubCategory"),
      },

      {
        path: "/expense-category-list",
        name: "expense-category-list",
        component: () =>
          import("@/views/Administrator/CategoryExpense/CategoryExpenseList"),
      },
      {
        path: "/expense-category-add",
        name: "expense-category-add",
        component: () =>
          import("@/views/Administrator/CategoryExpense/CategoryExpenseAdd"),
      },
      {
        path: "/expense-category-edit/:id",
        name: "expense-category-edit",
        component: () =>
          import("@/views/Administrator/CategoryExpense/CategoryExpenseEdit"),
      },

      {
        path: "/asset-location-list",
        name: "asset-location-list",
        component: () => import("@/views/Administrator/AssetLocation/List"),
      },
      {
        path: "/asset-location-add",
        name: "asset-location-add",
        component: () => import("@/views/Administrator/AssetLocation/Add"),
      },
      {
        path: "/asset-location-edit/:id",
        name: "asset-location-edit",
        component: () =>
          import("@/views/Administrator/AssetLocation/AssetLocationEdit"),
      },

      {
        path: "/expense/list",
        name: "expense-list",
        component: () => import("@/views/Expenses/ListExpense.vue"),
      },
      {
        path: "/exspanse/create",
        name: "exspanse-create",
        component: () => import("@/views/Expenses/ExpenseForm.vue"),
      },
      {
        path: "/exspanse/update/:id",
        name: "exspanse-update",
        component: () => import("@/views/Expenses/UpdateExpense.vue"),
      },

      {
        path: "/header",
        name: "header",
        component: () => import("@/components/Layouts/Header.vue"),
      },

      {
        path: "/assign-role-list",
        name: "assign-role-list",
        component: () => import("@/views/Settings/AssignRole/AssignRoleList"),
      },
      {
        path: "/assign-role",
        name: "assign-role",
        component: () => import("@/views/Settings/AssignRole/AssignRole"),
      },
      {
        path: "/assign-detail/:id",
        name: "assign-detail",
        component: () => import("@/views/Settings/AssignRole/AssignDetail"),
      },

      {
        path: "/assets-list",
        name: "assets-list",
        component: () => import("@/views/AssetManagement/AssetList"),
      },
      {
        path: "/add-assets",
        name: "add-assets",
        component: () => import("@/views/AssetManagement/AddAsset"),
      },
      {
        path: "/assets-edit/:id",
        name: "assets-edit",
        component: () => import("@/views/AssetManagement/AssetEdit"),
      },

      {
        path: "/till/open",
        name: "open-till",
        component: () =>
          import("@/views/CashManagement/ActionTill/Open/OpenTill.vue"),
      },
      {
        path: "/till/open/list",
        name: "status-till",
        component: () =>
          import("@/views/CashManagement/ActionTill/Open/ListOpenTill.vue"),
      },
      {
        path: "/till/close/list",
        name: "listclose-till",
        component: () =>
          import("@/views/CashManagement/ActionTill/Close/ListCloseTill.vue"),
      },
      {
        path: "/till/close",
        name: "close-till",
        component: () =>
          import("@/views/CashManagement/ActionTill/Close/CloseTill.vue"),
      },
      {
        path: "/change-password",
        name: "change-password",
        component: () =>
          import("@/views/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "",
    // component: ()=> import('@/components/Layouts/AuthLayout.vue'),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/UserLogin.vue"),
      },
      {
        path: "/create-brokerself",
        name: "create-brokerself",
        component: () => import("@/views/BrokerManagement/BrokerCreate.vue"),
      },
      {
        path: "/register",
        name: "user-register",
        component: () => import("@/views/UserRegister.vue"),
      },
      {
        path: "/receipt-print/:id",
        name: "receipt-print",
        component: () => import("@/views/Receipts/PrintReceipt"),
      },
      {
        path: "/print_invoice/:id",
        name: "print_invoice",
        component: () => import("@/views/Invoices/PrintInvioce.vue"),
      },
      {
        path: "/print-contract/:id",
        name: "print-contract",
        component: () => import("@/views/Contracts/PrintContract.vue"),
      },
      {
        path: "/view-booking/:id",
        name: "view-booking",
        component: () => import("@/views/Booking/ShowBooking.vue"),
      },

      // ===================Report Route============================
      {
        path: "/building-reports",
        name: "building-reports",
        component: () => import("@/views/Reports/BuildingReports/BuildingReport.vue"),
      },

      {
        path: "/invoice-reports",
        name: "invoice-reports",
        component: () => import("@/views/Reports/InvoiceReports/invoiceReport.vue"),
      },

      {
        path: "/receipt-reports",
        name: "receipt-reports",
        component: () => import("@/views/Reports/ReceipReports/ReceipReport.vue"),
      },

      {
        path: "/contract-reports",
        name: "contract-reports",
        component: () => import("@/views/Reports/ContractReports/ContractReport.vue"),
      },

      {
        path: "/exspense-reports",
        name: "exspense-reports",
        component: () => import("@/views/Reports/ExspenseReports/ExspenseReport.vue"),
      },

      {
        path: "/transfer-reports",
        name: "transfer-reports",
        component: () => import("@/views/Reports/TransferReports/TransferReport.vue"),
      },
      {
        path: "/bank-info/:id",
        name: "bank-info",
        component: () => import("@/views/UserManagement/User/UserBankInfo.vue"),
      },


    ],
  },
  
]:[
  {
    path: "/",
    // component: () => import("@/components/Layouts/LayoutUserManagment.vue"),
    meta: {
      requiresAuth: true,
      requiredRole: 3, // Only accessible to admin (role 1)
    },
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/UserLogin.vue"),
      },
      {
        path: "/",
        name: "HomePage",
        component: () => import("@/views/BuildingOwner/Home.vue"),
      },
      {
        path: "/building",
        name: "Building",
        component: () => import("@/views/BuildingOwner/Building.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/BuildingOwner/Account.vue"),
      },
      {
        path: "/room-list/:id",
        name: "RoomList",
        component: () => import("@/views/BuildingOwner/RoomList.vue"),
      },
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkExactActiveClass: "bg-primary text-white border-bottom",
});

export default router;