<template>
  <div v-if="isLoginPage || isRegisterPage || isReceiptPrint || isInvoicePrint || isContractPrint">
    <!-- Render login content directly -->
    <router-view />
  </div>
  <div v-else>
    <div class="layout" :class="{ 'dark-mode': isDarkMode }">
      <SideBar v-if="Role !== 3" @handleSmall="smallMenu" :is-toggled="resize" @toggle-sidebar="handleSidebarToggle"
        @button-clicked="toggleSidebar" />
      <div class="userManagement" :class="Role !== 3 ? ['main', { collapsed: !resize }] : 'w-100'">
        <Header @handleResize="resizeMenu" />
        <div :class="['bg-body-secondary content', { 'content-expanded': !isSidebarCollapsed }]"
          :style="{ padding: Role !== 3 ? '10px' : 0 }">
          <div v-if="isShow" @click="resizeMenu" class="w-100 h-100 bg-none"></div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import SideBar from "./SideBar.vue";
import Header from "./Header.vue";
import api from "@/services/apiService";

export default {
  components: {
    SideBar,
    Header,
  },
  computed: {

    isLoginPage() {
      // Check if the current route is "/login"
      return this.$route.path === "/login";
    },
    isRegisterPage() {
      // Check if the current route is "/login"
      return this.$route.path === "/register";
    },
    isReceiptPrint() {
      // Check if the current route is "/login"
      const { path, params } = this.$route;
      return path === `/receipt-print/${params.id}`;
    },
    isInvoicePrint() {
      // Check if the current route is "/login"
      const { path, params } = this.$route;
      return path === `/print_invoice/${params.id}`;
    },
    isContractPrint() {
      // Check if the current route is "/login"
      const { path, params } = this.$route;
      return path === `/print-contract/${params.id}`;
    },
    // isBookingPrint() {
    //   // Check if the current route is "/login"
    //   const { path, params } = this.$route;
    //   return path === `/view-booking/${params.id}`;
    // },
  },
  setup() {
    const isCollapsed = ref(true);

    const toggleSidebar = () => {
      isCollapsed.value = !isCollapsed.value;
    };
    return {
      isCollapsed,
      toggleSidebar,
    };
  },

  data() {
    return {
      isDarkMode: false,
      isSidebarCollapsed: false,
      resize: true,
      Role: 0,
      isShow: false,
      Id: this.$route.params.id,
    };
  },
  methods: {
    async fetchUser() {
      try {
        const response = await api.get("/user");
        this.Role = response.data.role_id;
      } catch (error) {
        console.log(error)
      }
    },
    resizeMenu() {
      this.resize = !this.resize;
      if (window.screen.width <= 768) {
        this.resize ? this.isShow = false : this.isShow = true;
      }
    },
    smallMenu() {
      this.isShow = false;
      this.resize = !this.resize;
    },
    toggleDarkMode(isDark) {
      this.isDarkMode = isDark;
      localStorage.setItem("darkMode", isDark ? "true" : "false");
    },
    handleSidebarToggle(isCollapsed) {
      this.isSidebarCollapsed = isCollapsed;
    },
  },
  created() {
    const darkModePreference = localStorage.getItem("darkMode");
    this.isDarkMode = darkModePreference === "true";
    this.fetchUser();

  },
};
</script>

<style>
.layout {
  display: flex;
  width: 100%;
}

@media (max-width: 768px) {
  .bg-none {
    background: #0000;
    position: absolute;
    width: 100vw;
    height: calc(100vh - 60px);
    z-index: 99;
    bottom: 0;
  }
}
</style>