<template>
  <div :class="['navbar-top', { collapsed: isCollapsed }]"
    class="d-flex flex-row justify-content-between align-items-center p-1">
    <div class="Option">
      <ul class="d-flex">
        <li v-if="roleId != 3" @click="toggleSidebar">
          <span @click="resizeMenu"><i class="fa-solid fa-bars"></i></span>
        </li>
        <li><a href="#">Home</a></li>
        <li><a href="#">Contact</a></li>
      </ul>
    </div>

    <div class="navbar-brand mt-3 m-3 d-flex align-items-center"></div>
    <div class="text d-flex align-items-center">
      <!-- Dark Mode Toggle -->
      <div class="nav-item me-4 mt-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="toggle" @change="toggleDarkMode" :checked="isDarkMode" />
          <div class="display">
            <label for="toggle">
              <div class="circle">
                <!-- Dark Mode Icons (Optional) -->
              </div>
            </label>
          </div>
        </div>
      </div>

      <!-- Notifications Icon -->
      <!-- <div class="nav-item me-4 mt-2"> -->
      <!-- <a href="#" class="nav-link text-white position-relative"> -->
      <!-- <i class="fas fa-bell fa-lg"></i> -->
      <!-- Font Awesome bell icon -->
      <!-- <span class="badge bg-danger position-absolute top-0 start-100 translate-middle rounded-pill"> -->
      <!-- 3 -->
      <!-- </span> -->
      <!-- </a> -->
      <!-- </div> -->

      <!-- User Profile Dropdown -->
      <div v-if="!user">
        <a class="nav-link text-primary" href="#/login">Login</a>
      </div>
      <div class="nav-item dropdown mt-1" v-if="user || Datauser">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="d-none d-lg-inline-flex">{{ user.name }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end border-0 rounded-0 rounded-bottom m-0">
          <a class="dropdown-item" @click="goToProfile">Profile</a>
          <a class="dropdown-item" href="#">Settings</a>
          <a class="dropdown-item" href="#/change-password">Change Password</a>
          <a class="dropdown-item" href="#/login" @click="logout">Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/apiService.js";

export default {
  name: "HeaderManagement",
  data() {
    return {
      isDarkMode: document.body.classList.contains("dark-mode"),
      user: null,
      Datauser: null,
      roleId: '',
    };
  },
  methods: {
    resizeMenu() {
      this.$emit("handleResize");
    },
    async toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      document.body.classList.toggle("dark-mode", this.isDarkMode);
    },
    async fetchUserData() {
      try {
        const token = localStorage.getItem("token");
        this.roleId = localStorage.getItem("roleId");
        if (!token) {
          this.user = null;
          this.Datauser = null;
          return;
        }
        const response = await api.get("/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.user = response.data;

        if (!this.user || !this.user.userinfo_id) {
          console.error("User info ID not found");
          return;
        }

        const userInfoResponse = await api.get(
          `/user_info/${this.user.userinfo_id}`
        );

        this.Datauser = userInfoResponse.data.data.photo;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    register() {
      this.$router.push("/register");
    },
    goToProfile() {
      if (this.user && this.user.userinfo_id) {
        this.$router.push(`/view-user/${this.user.userinfo_id}`);
      } else {
        console.error("User ID not available");
      }
    },
    logout() {
      // Remove token from localStorage
      localStorage.removeItem("token");

      // Clear user data
      this.user = null;
      this.Datauser = null;

      // Redirect to login page
      this.$router.push("/login");
    },
  },
  created() {
    this.fetchUserData();
  },
};
</script>
